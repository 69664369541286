var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AError", { attrs: { api: this.api } }),
      _c(
        "div",
        { staticClass: "px-2" },
        [
          _c("v-combobox", {
            attrs: {
              dense: "",
              outlined: "",
              label: "Customer Service ( Optional )",
              items: _vm.csOptions,
              "item-text": "username",
              "item-value": "id",
              loading: this.api.isLoading,
              "return-object": "",
            },
            on: { change: _vm.updateParentValue },
            model: {
              value: _vm.cs,
              callback: function ($$v) {
                _vm.cs = $$v
              },
              expression: "cs",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }